<template>
    <div
        class="cta-block"
        :style="`background-image: linear-gradient(to right, ${backgroundColorStart}, ${backgroundColorEnd})`"
    >
        <div class="cta-block__header">
            <div
                v-if="title.length > 0"
                class="cta-block__title"
                :style="`color: ${titleColor}`"
            >
                {{ title }}
            </div>

            <div
                v-if="subTitle.length > 0"
                class="cta-block__sub-title"
                :style="`color: ${subtitleColor}`"
            >
                {{ subTitle }}
            </div>
        </div>

        <div class="cta-block__content-container">
            <div class="cta-block__usp-container">
                <!-- eslint-disable vue/no-v-html -->
                <div
                    v-if="usps.length > 0"
                    class="cta-block__usps"
                    v-html="usps"
                ></div>

                <CtaButton
                    v-if="buttonText.length > 0"
                    :button="buttonText"
                    :description="buttonDescription"
                    :redirect-url="buttonUrl"
                />

                <img
                    v-if="uspsImage"
                    :src="uspsImage?.url ?? ''"
                    :alt="uspsImage?.alt ?? title"
                    :width="uspsImage?.width > 1 ? uspsImage.width : null"
                    :height="uspsImage?.height > 1 ? uspsImage.height : null"
                    loading="lazy"
                >
            </div>

            <div
                v-if="optimizeText.length > 0"
                class="cta-block__optimize-home-container"
            >
                <div
                    v-if="optimizeImage"
                    class="cta-block__optimize-image-container"
                >
                    <div class="cta-block__optimize-image">
                        <img
                            :src="optimizeImage?.url ?? ''"
                            :alt="optimizeImage?.alt ?? title"
                            :width="optimizeImage?.width > 1 ? optimizeImage.width : null"
                            :height="optimizeImage?.height > 1 ? optimizeImage.height : null"
                            loading="lazy"
                        >
                    </div>
                </div>

                <div class="cta-block__optimize-text">
                    {{ optimizeText }}
                </div>
            </div>
            <div
                v-if="optimizeImage"
            >
                <img
                    :src="optimizeImage?.url ?? ''"
                    :alt="optimizeImage?.alt ?? title"
                    :width="optimizeImage?.width > 1 ? optimizeImage.width : null"
                    :height="optimizeImage?.height > 1 ? optimizeImage.height : null"
                    loading="lazy"
                >
            </div>
        </div>

        <div
            class="cta-block__bottom"
            :style="`background-color: ${bottomBackgroundColor}`"
        >
            <div class="cta-block__bottom-container">
                <div
                    class="cta-block__bottom-title-container"
                    :style="`color: ${bottomTextColor}`"
                >
                    <div
                        v-if="bottomTitle.length > 0"
                        class="cta-block__bottom-title"
                    >
                        {{ bottomTitle }}
                    </div>
                    <div
                        v-if="bottomSubTitle.length > 0"
                        class="cta-block__bottom-sub-title"
                    >
                        {{ bottomSubTitle }}
                    </div>
                </div>

                <div
                    v-if="phone.length > 0"
                    class="cta-block__bottom-phonenumber"
                    :style="`color: ${phoneTextColor}`"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                            clip-rule="evenodd"
                        />
                    </svg>

                    <a
                        :href="`tel:${$page.props.brand.countryCode}${phone.replace(/\s|-/g, '').replace(/^0+/, '')}`"
                    >
                        {{ phone }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';
import CtaButton from '../cta-button/watson-theme-cta-button.vue';

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: '',
    },
    subTitle: {
        type: String,
        required: false,
        default: '',
    },
    usps: {
        type: String,
        required: false,
        default: '',
    },
    uspsImage: {
        type: [Object, Boolean],
        required: false,
        default: null,
    },
    buttonText: {
        type: String,
        required: false,
        default: '',
    },
    buttonDescription: {
        type: String,
        required: false,
        default: '',
    },
    buttonUrl: {
        type: String,
        required: false,
        default: '',
    },
    optimizeText: {
        type: String,
        required: false,
        default: '',
    },
    optimizeImage: {
        type: [Object, Boolean],
        required: false,
        default: null,
    },
    bottomTitle: {
        type: String,
        required: false,
        default: '',
    },
    bottomSubTitle: {
        type: String,
        required: false,
        default: '',
    },
    phone: {
        type: String,
        required: false,
        default: '',
    },
    pageBackgroundColorStart: {
        type: String,
        required: false,
        default: '',
    },
    pageBackgroundColorEnd: {
        type: String,
        required: false,
        default: '',
    },
    pageTitleColor: {
        type: String,
        required: false,
        default: '',
    },
    pageSubtitleColor: {
        type: String,
        required: false,
        default: '',
    },
    pageUspTextColor: {
        type: String,
        required: false,
        default: '',
    },
    pageUspImageColor: {
        type: String,
        required: false,
        default: '',
    },
    pageOptimiseBackground: {
        type: String,
        required: false,
        default: '',
    },
    pageOptimiseTextColor: {
        type: String,
        required: false,
        default: '',
    },
    pageButtonBackground: {
        type: String,
        required: false,
        default: '',
    },
    pageButtonTextColor: {
        type: String,
        required: false,
        default: '',
    },
    pageButtonSubtitleColor: {
        type: String,
        required: false,
        default: '',
    },
    pageBottomTextColor: {
        type: String,
        required: false,
        default: '',
    },
    pageBottomBackgroundColor: {
        type: String,
        required: false,
        default: '',
    },
    pagePhoneTextColor: {
        type: String,
        required: false,
        default: '',
    },
    pageBackgroundColorStartMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageBackgroundColorEndMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageTitleColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageSubtitleColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageUspImageColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageUspTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageOptimiseBackgroundMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageOptimiseTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageButtonBackgroundMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageButtonTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageButtonSubtitleColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageBottomTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pageBottomBackgroundColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    pagePhoneTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    backgroundColorStart: {
        type: String,
        required: false,
        default: '',
    },
    backgroundColorEnd: {
        type: String,
        required: false,
        default: '',
    },
    titleColor: {
        type: String,
        required: false,
        default: '',
    },
    subtitleColor: {
        type: String,
        required: false,
        default: '',
    },
    uspTextColor: {
        type: String,
        required: false,
        default: '',
    },
    uspImageColor: {
        type: String,
        required: false,
        default: '',
    },
    optimiseBackgroundColor: {
        type: String,
        required: false,
        default: '',
    },
    optimiseTextColor: {
        type: String,
        required: false,
        default: '',
    },
    buttonBackground: {
        type: String,
        required: false,
        default: '',
    },
    buttonTextColor: {
        type: String,
        required: false,
        default: '',
    },
    buttonSubtitleColor: {
        type: String,
        required: false,
        default: '',
    },
    bottomTextColor: {
        type: String,
        required: false,
        default: '',
    },
    bottomBackgroundColor: {
        type: String,
        required: false,
        default: '',
    },
    phoneTextColor: {
        type: String,
        required: false,
        default: '',
    },
    backgroundColorStartMobile: {
        type: String,
        required: false,
        default: '',
    },
    backgroundColorEndMobile: {
        type: String,
        required: false,
        default: '',
    },
    titleColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    subtitleColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    uspTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    uspImageColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    buttonBackgroundMobile: {
        type: String,
        required: false,
        default: '',
    },
    buttonTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    buttonSubtitleColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    optimiseBackgroundColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    optimiseTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    bottomTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    bottomBackgroundColorMobile: {
        type: String,
        required: false,
        default: '',
    },
    phoneTextColorMobile: {
        type: String,
        required: false,
        default: '',
    },
});

const page = usePage();
const themeSettings = page.props.themeSettings;

const colourScheme = {
    backgroundColourStart: themeSettings.ctaBlockBackgroundColourStart,
    backgroundColourEnd: themeSettings.ctaBlockBackgroundColourEnd,
    titleColour: themeSettings.ctaBlockTitleColour,
    subtitleColour: themeSettings.ctaBlockSubtitleColour,
    uspImageColour: themeSettings.ctaBlockUspImageColour,
    bottomTextColour: themeSettings.ctaBlockBottomTextColour,
    bottomBackgroundColour: themeSettings.ctaBlockBottomBackgroundColour,
    phoneTextColour: themeSettings.ctaBlockPhoneTextColour,
};

const checkForColor = prop => {
    const opacity = checkColorOpacity(prop);
    if (opacity === ',0)' || opacity === '' || prop === undefined) {
        return null;
    }
    return prop;
};

const checkColorOpacity = color => {
    return color?.slice(-3);
};

const backgroundColorStart = props.backgroundColorStart || props.pageBackgroundColorStart || colourScheme.backgroundColourStart || '#deeefa';
const backgroundColorEnd = props.backgroundColorEnd || props.pageBackgroundColorEnd || colourScheme.backgroundColourEnd || '#deeefa';
const titleColor = props.titleColor || props.pageTitleColor || colourScheme.titleColour || '#3daee2';
const subtitleColor = props.subtitleColor || props.pageSubtitleColor || colourScheme.subtitleColour || '#535456';
const uspText = checkForColor(props.uspTextColor) || checkForColor(props.pageUspTextColor) || '#000';
const uspImageColor = props.uspImageColor || props.pageUspImageColor || colourScheme.uspImageColour || 'rgb(var(--base-200))';
const optimiseBackground = checkForColor(props.optimiseBackgroundColor) || checkForColor(props.pageOptimiseBackground) || '#c3e0f3';
const optimiseText = checkForColor(props.optimiseTextColor) || checkForColor(props.pageOptimiseTextColor) || 'rgb(68 68 68)';
const buttonBackground = checkForColor(props.buttonBackground) || checkForColor(props.pageButtonBackground) || 'rgb(255, 111, 0)';
const buttonTextColor = checkForColor(props.buttonTextColor) || checkForColor(props.pageButtonTextColor) || '#ffffff';
const buttonSubtitleColor = checkForColor(props.buttonSubtitleColor) || checkForColor(props.pageButtonSubtitleColor) || 'rgb(148, 148, 148)';
const bottomTextColor = props.bottomTextColor || props.pageBottomTextColor || colourScheme.bottomTextColour || '#5f6265';
const bottomBackgroundColor = props.bottomBackgroundColor || props.pageBottomBackgroundColor || colourScheme.bottomBackgroundColour || '#fff';
const phoneTextColor = props.phoneTextColor || props.pagePhoneTextColor || colourScheme.phoneTextColour || 'rgb(var(--base-400))';

const backgroundColorStartMobile = checkForColor(props.backgroundColorStartMobile) || checkForColor(props.pageBackgroundColorStartMobile) || backgroundColorStart;
const backgroundColorEndMobile = checkForColor(props.backgroundColorEndMobile) || checkForColor(props.pageBackgroundColorEndMobile) || backgroundColorEnd;
const titleColorMobile = checkForColor(props.titleColorMobile) || checkForColor(props.pageTitleColorMobile) || titleColor;
const subtitleColorMobile = checkForColor(props.subtitleColorMobile) || checkForColor(props.pageSubtitleColorMobile) || subtitleColor;
const uspImageColorMobile = checkForColor(props.uspImageColorMobile) || checkForColor(props.pageUspImageColorMobile) || uspImageColor;
const uspTextColorMobile = checkForColor(props.uspTextColorMobile) || checkForColor(props.pageUspTextColorMobile) || uspText;
const optimiseBackgroundMobile = checkForColor(props.optimiseBackgroundColorMobile) || checkForColor(props.pageOptimiseBackgroundMobile) || optimiseBackground;
const optimiseTextMobile = checkForColor(props.optimiseTextColorMobile) || checkForColor(props.pageOptimiseTextColorMobile) || optimiseText;
const buttonBackgroundMobile = checkForColor(props.buttonBackgroundMobile) || checkForColor(props.pageButtonBackgroundMobile) || buttonBackground;
const buttonTextColorMobile = checkForColor(props.buttonTextColorMobile) || checkForColor(props.pageButtonTextColorMobile) || buttonTextColor;
const buttonSubtitleColorMobile = checkForColor(props.buttonSubtitleColorMobile) || checkForColor(props.pageButtonSubtitleColorMobile) || buttonSubtitleColor;
const bottomTextColorMobile = checkForColor(props.bottomTextColorMobile) || checkForColor(props.pageBottomTextColorMobile) || bottomTextColor;
const bottomBackgroundColorMobile = checkForColor(props.bottomBackgroundColorMobile) || checkForColor(props.pageBottomBackgroundColorMobile) || bottomBackgroundColor;
const phoneTextColorMobile = checkForColor(props.phoneTextColorMobile) || checkForColor(props.pagePhoneTextColorMobile) || phoneTextColor;
</script>

<style lang="scss" scoped>
@use "watson-theme-cta-block";

.cta-block {
  --usp-color: v-bind("uspText");
  --optimise-background: v-bind("optimiseBackground");
  --optimise-text: v-bind("optimiseText");
  --button-background: v-bind("buttonBackground");
  --button-text-color: v-bind("buttonTextColor");
  --button-subtitle-color: v-bind("buttonSubtitleColor");
  --background-color-start-mobile: v-bind("backgroundColorStartMobile");
  --background-color-end-mobile: v-bind("backgroundColorEndMobile");
  --title-color-mobile: v-bind("titleColorMobile");
  --subtitle-color-mobile: v-bind("subtitleColorMobile");
  --usp-text-mobile: v-bind('uspTextColorMobile');
  --optimise-background-mobile: v-bind("optimiseBackgroundMobile");
  --optimise-text-mobile: v-bind("optimiseTextMobile");
  --button-background-mobile: v-bind("buttonBackgroundMobile");
  --button-text-color-mobile: v-bind("buttonTextColorMobile");
  --button-subtitle-color-mobile: v-bind("buttonSubtitleColorMobile");
  --bottom-text-mobile: v-bind("bottomTextColorMobile");
  --bottom-background-mobile: v-bind("bottomBackgroundColorMobile");
  --phone-text-mobile: v-bind("phoneTextColorMobile");
}

.cta-block :deep(.usps ul li:before) {
  background-color: v-bind('uspImageColor');

  @media screen and (max-width: 1198px ) {
    background: v-bind("uspImageColorMobile");
  }
}
</style>
